import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import '@fortawesome/fontawesome-free/css/all.min.css';
import "react-toastify/dist/ReactToastify.css"; // Import the default styles
import "./index.css"; // Ensure this is imported
import CookieConsent from './CookieConsent';

const App = () => {
  const [videoId, setVideoId] = useState("");
  const [selectedGenre, setSelectedGenre] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedValue, setSelectedValue] = useState(""); // State for dropdown value
  const [placeholderText, setPlaceholderText] = useState("Select a Music Genre"); // State for placeholder text

  const genres = [
	{ text: "Pop", value: "AfG2G0ljIPg" },
	{ text: "Rock", value: "H63mXffnS1E" },
	{ text: "Electronic", value: "-CSeaKa_7Vg" },
	{ text: "Country", value: "-cZaH7dHGgI" },
	{ text: "Soul", value: "90b4m0xhGGM" },
	{ text: "R&B", value: "XP3bzVntsD8" },
	{ text: "Reggaeton", value: "teeCy2OsVKk" },
	{ text: "Dance", value: "87k0zjmxEFE" },
	{ text: "Rap", value: "6WB6cgHrl1I" },
	{ text: "Gregorian Chant", value: "pr_0bkIp3JU" },
	{ text: "Polka", value: "FVZtj9volzo" }
  ];

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedText = event.target.options[event.target.selectedIndex].text;
    setVideoId(selectedValue);
    setSelectedGenre(selectedText);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(selectedGenre).then(() => {
      toast.success(`"${selectedGenre}" copied to the clipboard!`);
    });
  };

  const filteredGenres = genres.filter(genre =>
    genre.text.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (filteredGenres.length === 1) {
      const singleGenre = filteredGenres[0];
      setSelectedValue(singleGenre.value); // Set the selected value
      setVideoId(singleGenre.value);
      setSelectedGenre(singleGenre.text);
      setPlaceholderText("Select a Music Genre"); // Reset placeholder text
    } else if (filteredGenres.length > 1) {
      setPlaceholderText(`${filteredGenres.length} Music Genres Found`); // Update placeholder text
      setSelectedValue(""); // Clear selected value if there are multiple options
    } else {
      setPlaceholderText("No Music Genres Found"); // Placeholder for no options
      setSelectedValue(""); // Clear selected value
    }
  }, [filteredGenres]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-r from-purple-500 to-blue-500 text-white p-6">
      <CookieConsent />

      {/* Title Section */}
      <a 
        href="https://aimusiccatalog.com" 
        className="text-5xl font-extrabold mb-6 text-center hover:text-blue-300 transition duration-300 ease-in-out"
      >
        AI Music Catalog
      </a>

      {/* Instruction Text */}
      <p className="text-xl mb-6 text-center">
        Find Your Favourite Music Genre or Style To Create Your Own Songs Using AI
      </p>

      {/* Search Input */}
      <div className="relative w-64 mb-4">
        <input
          type="text"
          placeholder="Search genres..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="block w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 leading-tight focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 shadow-lg transition duration-200 ease-in-out"
        />
      </div>

      {/* Combobox Section */}
      <div className="relative w-64 mb-8">
        <select
          value={selectedValue} // Set dropdown value
          onChange={handleSelectChange}
          className="block appearance-none w-full bg-white text-gray-700 border border-gray-300 rounded-lg py-3 px-4 pr-8 leading-tight focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-purple-500 shadow-lg transition duration-200 ease-in-out"
        >
          <option value="">{placeholderText}</option> {/* Updated placeholder text */}
          {filteredGenres.map((genre) => (
            <option key={genre.value} value={genre.value}>
              {genre.text}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7 10l5 5 5-5H7z"/></svg>
        </div>
      </div>

      {/* Video Section */}
      {videoId && (
        <div className="w-full max-w-md mb-8">
          <div className="relative" style={{ paddingBottom: "100%", height: 0 }}>
            <iframe
              className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}

      {/* Selected Genre Text */}
      {selectedGenre && (
        <div className="flex flex-col items-center">
          <p className="text-2xl mb-4">{selectedGenre}</p>
          <button
            onClick={copyToClipboard}
            className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition duration-200 ease-in-out"
          >
            Copy to Clipboard
          </button>
        </div>
      )}

      {/* Disclaimer */}
      <p className="text-sm mt-4 text-center">
        Disclaimer: This website is not affiliated with suno.com or any other website that creates AI songs.
      </p>

      {/* Social Media Links */}
      <div className="mt-8 flex space-x-8">
        <a
          href="https://www.youtube.com/@AIMusicCatalog" // Replace with your actual YouTube channel URL
          className="text-white text-2xl hover:text-yellow-300 transition duration-300 ease-in-out"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-youtube"></i>
        </a>
        <a
          href="https://x.com/aimusicatalog" // Replace with your actual Twitter handle
          className="text-white text-2xl hover:text-blue-300 transition duration-300 ease-in-out"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-x-twitter"></i>
        </a>

        <a href='https://ko-fi.com/Y8Y311QTO4'>
          <img 
            height='36' 
            style={{ border: '0px', height: '36px' }} 
            src='https://storage.ko-fi.com/cdn/kofi3.png?v=3' 
            alt='Buy Me a Coffee at ko-fi.com' 
          />
        </a>

		<a
			href="https://www.producthunt.com/posts/ai-music-catalog?embed=true&utm_source=badge-featured&utm_medium=badge&utm_souce=badge-ai&#0045;music&#0045;catalog"
			target="_blank"
			rel="noopener noreferrer"
		>
			<img
			src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=480396&theme=light"
			alt="AI Music Catalog - Find Your Favourite Music Genre for your own AI Songs | Product Hunt"
			style={{ width: '167px', height: '36px' }}
			width="250"
			height="54"
			/>
		</a>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default App;

import React, { useState } from 'react';

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(true);

  const handleAccept = () => {
    setShowBanner(false);
    // Here you can add your logic to save the user's consent choice, e.g., set a cookie or store in local storage.
  };

  return (
    showBanner && (
      <div className="fixed bottom-0 w-full bg-gray-800 text-white p-4 flex justify-between items-center z-50">
        <p className="text-sm">
          We use cookies to improve your experience on our site. By continuing to use our site, you accept our use of cookies.
        </p>
        <button 
          onClick={handleAccept} 
          className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md ml-4"
        >
          Accept
        </button>
      </div>
    )
  );
};

export default CookieConsent;
